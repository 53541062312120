import Rails from '@rails/ujs'

document.addEventListener('DOMContentLoaded', () => {
  document
    .querySelector('.unsubscribe-form .all-checkbox')
    ?.addEventListener('change', () => {
      let form = document.querySelector('.unsubscribe-form')
      let allCheckbox = form.querySelector('.all-checkbox')

      form.querySelectorAll('.event-id-checkbox').forEach((eventIdCheckbox) => {
        eventIdCheckbox.checked = !allCheckbox.checked
        eventIdCheckbox.disabled = allCheckbox.checked
      })

      Rails.fire(form, 'submit')
    })

  document
    .querySelectorAll('.unsubscribe-form .event-id-checkbox')
    ?.forEach((eventIdCheckbox) => {
      eventIdCheckbox.addEventListener('change', () => {
        Rails.fire(document.querySelector('.unsubscribe-form'), 'submit')
      })
    })
})
