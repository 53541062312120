document.addEventListener('DOMContentLoaded', () => {
  document
    .querySelector('form.disable-on-submit')
    ?.addEventListener('submit', (event) => {
      console.log(event.target)
      event.target
        ?.querySelector('button[type=submit]')
        ?.classList?.add('disabled')
    })
})
