import Rails from '@rails/ujs'

import i18n from '../src/i18n/i18n.js.erb'

import Tooltip from 'bootstrap/js/dist/tooltip'
import 'bootstrap/js/dist/collapse'

import '../src/infosite/general.js'
import '../src/infosite/leads.js'
import '../src/infosite/unsubscriptions.js'

import '../src/shared/intl-tel-inputs'

window.I18n = i18n

Rails.start()

document.addEventListener('DOMContentLoaded', () => {
  ;[].slice
    .call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
    .map((tooltipTriggerEl) => {
      return new Tooltip(tooltipTriggerEl)
    })
})
