document.addEventListener('DOMContentLoaded', () => {
  let appStoreLink = document.querySelector('.app-store-link')
  let googlePlayLink = document.querySelector('.google-play-link')

  if (appStoreLink && googlePlayLink) {
    if (/android/i.test(navigator.userAgent)) {
      appStoreLink.classList.add('d-none')
    } else if (/ipad|iphone|ipod/i.test(navigator.userAgent)) {
      googlePlayLink.classList.add('d-none')
    }
  }
})
